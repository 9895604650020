/* HACK HACK HACK HACK HACK
 *
 * Do not add new styles to this file.
 *
 * It's only here to restore some base styles that we haven't been able to fix in our proper LESS
 * styles yet.
 */
a,
a:link,
a:visited,
a:active {
  color: #ce0058;
}
a:hover {
  color: #9c0042;
}
a.title-link {
  color: #2f96b4;
}
a.blend,
a.blend:link,
a.blend:visited {
  color: inherit;
}
a.blend:hover {
  text-decoration: underline;
}
