@import (reference) "../shared/_variables.less";

/* HACK HACK HACK HACK HACK
 *
 * Do not add new styles to this file.
 *
 * It's only here to restore some base styles that we haven't been able to fix in our proper LESS
 * styles yet.
 */

a {
  &,
  &:link,
  &:visited,
  &:active {
    color: @uberPink;
  }

  &:hover {
    color: @uberRubineDark;
  }

  &.title-link {
    color: @uberBlue;
  }

  &.blend,
  &.blend:link,
  &.blend:visited {
    color: inherit;
  }

  &.blend:hover {
    text-decoration: underline;
  }
}
